import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

/*
 We can pass a reference to a container element that will be used to render the children primary for fullscreen and modal dialogs
  */
interface ModalPortalProps {
    children: ReactNode
    container?: HTMLElement | null
}

const ModalPortal = ({ children, container = null }: ModalPortalProps) => {
    const [mounted, setMounted] = useState<boolean>(false)

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return mounted ? createPortal(children, container ?? document.body) : null
}

export default ModalPortal
