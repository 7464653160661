import React, { forwardRef } from 'react'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import { BasicButtonProps } from '@/types/globalTypes'

const BasicButton = forwardRef<HTMLButtonElement, BasicButtonProps>(({
    type = 'button',
    label,
    onClick,
    disabled,
    className,
    icon,
    labelClassName,
    children,
    isLoading = false,
    style = {},
}, ref) => {
    return (
        <button
            ref={ref}
            type={type}
            className={className}
            onClick={onClick}
            disabled={disabled || isLoading}
            style={style}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {icon}
                    {label ? <span className={labelClassName}>{label}</span> : null}
                    {children}
                </>
            )}
        </button>
    )
})

BasicButton.displayName = 'BasicButton'

export default BasicButton
